//
// (Explicit Dependencies) Import UXCore2 components.
//
import Accordion from '@ux/accordion';
import Alert from '@ux/alert';
import Button from '@ux/button';
import Collapsible from '@ux/collapsible';
import Dropdown from '@ux/dropdown';
import Fieldset from '@ux/fieldset';
import Growl from '@ux/growl';
import MessageOverlay from '@ux/message-overlay';
import Modal from '@ux/modal';
import Pagination from '@ux/pagination-legacy';
import Pivots from '@ux/pivot';
import ProgressBar from '@ux/progress-bar';
import ProgressSteps from '@ux/progress-steps';
import Spinner from '@ux/spinner';
import Table from '@ux/table';
import Tabs from '@ux/tabs';
import Text from '@ux/text';
import Toggle from '@ux/toggle';
import Tooltip from '@ux/tooltip-legacy';
import request from '@godaddy/request';
import * as utils from '@ux/util';
import * as Validation from 'react-validation-context';

const versions = {
  // eslint-disable-next-line no-process-env
  [process.env.BUNDLE_NAME]: process.env.BUNDLE_VERSION
};

//
// Due to Safari lacking support for :focus-visible we need to add a temp script
// that adds similar behavior. This script adds a class on the body and using css
// variables we use :focus to do the same styles we would with :focus-visible.
// Once Safari has adequate :focus-visible support this js code will be removed
// and styles will switch from :focus to :focus-visible.
//
utils.registerKeyboardNavListener();

// Babel7 doesn't attach __esModule onto `import *`s even if they are actually es modules.
// For now, manually attach them for compatibility
[utils].forEach(function (thing) {
  Object.defineProperty(thing, '__esModule', {
    value: true
  });
});

//
// Also export Components to allow other applications depend directly on
// UXCore2. This cannot be done dynamically by destructuring the components as
// this would violate the assumption of statically defined imports/exports.
//
// Note: do NOT export as default, it will prevent `{ [Component] }` like imports.
//
export {
  Accordion,
  Alert,
  Button,
  Collapsible,
  Dropdown,
  Fieldset,
  Growl,
  MessageOverlay,
  Modal,
  Pagination,
  Pivots,
  ProgressBar,
  ProgressSteps,
  Spinner,
  Table,
  Tabs,
  Text,
  Toggle,
  Tooltip,
  request,
  utils,
  Validation,
  versions
};
